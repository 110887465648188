<template>
  <div class="home">
    <div class="top">
      <div class="top-info">
        <div class="info-item" v-for="item in topInfo" :key="item.id">
          <div class="imageBox" @click="clickShow(item)">
            <div class="image-logo" v-show="item.num">{{ item.num }}</div>
            <img :src="require(`@/assets/top_${item.imgUrl}.png`)" alt="" />
          </div>
          <div class="infoItemText">{{ item.title }}</div>
        </div>
      </div>
      <div class="top-warning" ref="textWarning">
        <img
          style="zIndex: 1000"
          src="@/assets/top_warningInfo2.png"
          ref="textImg"
          alt=""
        />
        <div
          style=""
          v-html="textRollWaringText"
          class="textRoll"
          ref="textRollRef"
        ></div>
      </div>
    </div>
    <div
      class="economyMove"
      v-for="(item, index) in homeInfo"
      :key="index + 'home'"
    >
      <HomeTitle
        :title="item.name"
        :msg="item.description"
        :isChangeColor="index == 0 ? false : true"
      />
      <div class="moveBox">
        <div
          class="boxPadding"
          v-for="(item2, index2) in item.subGroupList"
          :key="index2 + 'homeSub'"
        >
          <HomeTable :title="item2.name" :iconColor="setColor(index2).color2" />
          <div class="table-title2">
            <van-popover 
            placement="top"
            v-for="(item3, index3) in item2.quotaList"
            :key="index3+'home34'"
            theme="dark"
            ref="popover"
            @open="openPopover"
            v-model="item3.showPopover" trigger="click">
            <div class="popoverItem">
                <div class="popoverItemName">{{ item3.name }}</div>
                <div>数据来源：{{ item3.dataSource }}</div>
                <div>统计时间：{{ item3.tjsj }}</div>
            </div>
            <template #reference>
              <HomeTableMon3
              @click.native="openPop(item3)"
              :title="item3.name || '-'"
              :iconColor="setColor(index3).color1"
              :num="item3.value || '-'"
              :unit="item3.unit || '-'"
                    />
          </template>
          </van-popover>
          </div>
        </div>
      </div>
    </div>
    <!-- 城市体征指标 -->
    <div class="cityTarget">
      <HomeTitle
        :title="'城市体征指标'"
        :msg="'反应城市运行总体情况'"
        :isChangeColor="true"
      />
      <div class="cityBox">
        <div
          class="cityImgBox"
          v-for="(item2, index) in cityInfo"
          :key="item2.iconSrc"
          @click="
            () => {
              $router.push(`/safetyIndexSignboard?index=${index + 1}`);
            }
          "
        >
          <div class="cityStr">
            {{ item2.num }}
          </div>
          <img :src="require(`@/assets/icon_${item2.iconSrc}.png`)" alt="" />
        </div>
      </div>
    </div>
    <!-- 应用领域 -->
    <!-- <div class="application">
      <HomeTitle
        :title="'应用领域'"
        :msg="'八大专题领域应用数据'"
        :isChangeColor="true"
      />
      <div class="applicationBox">
        <div
          class="applicationItem"
          @click="showApplication(item.children)"
          v-for="(item, index) in applicationData"
          :key="index + 'app'"
        >
          <img :src="require(`@/assets/app_icon${index + 1}.png`)" alt="" />
          <div class="name">{{ item.label }}</div>
        </div>
      </div>
    </div> -->
    <ApplicationPop ref="appPopRef" :popData="appPopData" />
    <van-overlay z-index="999999" :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <img src="@/assets/pic.png" alt="" />
          <div class="block-text">
            您收到了 1 条<span style="color: #d1903e">运行简报</span>
          </div>
          <div class="block-button">
            <div class="button-left" @click="show = false">知道了</div>
            <div class="button-right" @click="$router.push({ path: '/move',query:{id:0} })">
              去看看
            </div>
          </div>
          <div class="close" @click="show = false">
            <img src="@/assets/esc_icon.png" alt="" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import HomeTitle from "@/components/home/homeTitle.vue";
import HomeTable from "@/components/home/homeTable.vue";
import HomeTableMon3 from "@/components/home/homeTableMon3.vue";
import ApplicationPop from "@/components/home/applicationPop.vue";
import {
  getWarningInfo,
  getCstzzb,
  getHomeInfo,
  getStatus,
} from "@/serve/api.js";
import appArr from "@/uits/urlData.js";
export default {
  name: "Home",
  components: {
    ApplicationPop,
    HomeTitle,
    HomeTable,
    HomeTableMon3,
  },
  computed: {
    setColor() {
      return function (i) {
        var obj = this.setColorList.find((el) => {
          return el.id == i.toString().substr(-1) % 3;
        });
        return obj;
      };
    },
  },
  data() {
    return {
      setColorList: [
        {
          id: 0,
          color1: "#4083FF",
          color2: "blue",
        },
        {
          id: 1,
          color1: "#FFB75C",
          color2: "yellow",
        },
        {
          id: 2,
          color1: "#2DC28A",
          color2: "green",
        },
      ],
      homeInfo: [],
      textRollWaringText: "",
      show: false,
      jbStatus: true,
      topInfo: [
        {
          title: "指标预警",
          imgUrl: "warning",
          num: 0,
          id: 1,
        },
        {
          title: "运行简报",
          imgUrl: "move",
          num: 0,
          id: 2,
          show: false,
        },
        {
          title: "收藏指标",
          imgUrl: "collection",
          num: 0,
          id: 3,
        },
      ],
      time: null,
      cityInfo: [
        {
          iconSrc: "safety",
          num: "",
        },
        {
          iconSrc: "clean",
          num: "",
        },
        {
          iconSrc: "orderly",
          num: "",
        },
        {
          iconSrc: "active",
          num: "",
        },
      ],
      applicationData: appArr,
      appPopData: [],
    };
  },

  methods: {
    openPop(item){
        console.log(item);
    },
    async getHomeInfo() {
      try {
        this.$store.commit("showLoading", "加载中...");
        const res = await getHomeInfo();
        this.homeInfo = res.groupList;
        this.homeInfo.forEach(el=>{
          el.subGroupList.forEach(el2=>{
            el2.quotaList.forEach(el3=>{
              this.$set(el3,'showPopover',false)
            })
          })
        })
        console.log(res,'info');
        this.$store.commit("hideLoading");
      } catch (e) {
        this.$store.commit("hideLoading");
        this.$toast(e.message);
      }
    },
    async getStatus() {
      const res = await getStatus();
      console.log(res);
      if (res.status == 1) {
        //未读
        this.topInfo[1].num = "!";
        // this.show = true;
      } else if (res.status == 2) {
        //已读
        this.topInfo[1].num = 0;
        this.show = false;
      } else if (res.status == 0) {
        //没有文件
        this.jbStatus = false;
      }
    },
    clickShow(item) {
      if (item.id === 2) {
        if (this.jbStatus) {
          this.$router.push("/MoveList");
        } else {
          this.$toast("没有文件");
        }
      }
      if (item.id === 3) {
        this.$router.push("/collection");
      }
      if (item.id === 1) {
        this.$router.push("/warningInfo");
      }
    },
    //滚动
    textSoll() {
      var speed = this.$refs.textWarning.offsetWidth;
      this.time = setInterval(() => {
        speed = speed - 1;
        this.$refs.textRollRef.style.left = `${speed}px`;
        if (
          -this.$refs.textRollRef.offsetLeft >
          this.$refs.textWarning.offsetWidth - this.$refs.textImg.offsetWidth
        ) {
          speed = this.$refs.textWarning.offsetWidth;
          this.$refs.textRollRef.style.left =
            this.$refs.textWarning.offsetWidth + "px";
        }
      }, 30);
    },
    async getWarningInfo() {
      const res = await getWarningInfo();
      const quotaList = res.quotaList;
      if (!quotaList) return;
      this.topInfo[0].num = quotaList.length; //徽标
      console.log(quotaList);
      quotaList.forEach((item) => {
        this.textRollWaringText += `
            ${item.name}-<span style="color:red">预警</span>; 
        `;
      });
    },
    async getCstzzb() {
      const res = await getCstzzb();
      this.cityInfo.forEach((el) => {
        for (var key in res) {
          if (el.iconSrc == key) {
            el.num = res[key];
          }
        }
      });
      console.log(res);
    },
    openPopover(){
      console.log(this.$refs.popover[0]);
    },
    showApplication(item) {
      this.appPopData = item;
      this.$refs.appPopRef.show();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.textSoll();
    });
    this.getWarningInfo();
    this.getCstzzb();
    this.getHomeInfo();
    this.getStatus();
  },
  destroyed() {
    clearInterval(this.time);
  },
};
</script>
<style scoped lang='scss'>
.home {
  color: #ffffff;
  overflow-x: hidden;
  padding-bottom: 62px;
  background: url(@/assets/topbg.png) 100% no-repeat;
  background-size: 100% 440px;
  background-position: top center;
  .top {
    width: 100vw;
    height: 350px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    //警告 运行 收藏
    .top-info {
      width: 510px;
      display: flex;
      justify-content: space-between;
      margin-top: 59px;
      margin-bottom: 24px;
      .info-item {
        .imageBox {
          position: relative;
          .image-logo {
            right: -20px;
            top: -10px;
            position: absolute;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border-bottom-left-radius: 0;
            background: rgb(240, 65, 85);
            border: 3px solid #ffff;
          }
          img {
            width: 80px;
            height: 87px;
          }
        }
        .infoItemText {
          font-size: 28px;
        }
      }
    }
    //警告
    .top-warning {
      width: 100%;
      height: 70px;
      background: #ffffff;
      text-align: left;
      .textRoll {
        width: fit-content;
        white-space: nowrap;
        line-height: 70px;
        position: relative;
        font-size: 24px;
        color: #353a45;
        left: 100vw;
        bottom: 0;
      }
      img {
        width: 81px;
        height: 70px;
        position: absolute;
      }
    }
  }
  .economyMove {
    .moveBox {
      width: 690px;
      display: flex;
      background: #fff;
      flex-direction: column;
      box-shadow: 0px 10px 20px 0px #d2e4f8;
      border-radius: 20px;
      margin: 0 auto 24px;
      box-sizing: border-box;
      padding: 20px 20px 20px 20px;
      .boxPadding {
        box-sizing: border-box;
        .table-title2 {
          margin-left: 14px;
          margin-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }
  }
  .cityBox {
    width: 690px;
    height: 388px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cityImgBox {
      width: 330px;
      height: 180px;
      position: relative;
      .cityStr {
        width: 110px;
        height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 43px;
        font-weight: 500;
        position: absolute;
        left: 35px;
        top: 34px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .application {
    .applicationBox {
      width: 678px;
      height: 360px;
      background: #ffffff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      box-sizing: border-box;
      flex-wrap: wrap;
      .applicationItem {
        box-sizing: border-box;
        padding: 0 10px;
        img {
          width: 102px;
          height: 102px;
        }
        .name {
          color: #353a45;
          font-size: 22px;
        }
      }
    }
  }
}
.close {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 27px auto 0px;
  img {
    width: 28px !important;
    height: 28px !important;
    margin: 0 !important;
  }
}
.my-swipe .van-swipe-item {
  background: #f0f7ff;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.block {
  width: 490px;
  height: 526px;
  border-radius: 20px;
  background-color: #fff;
  color: #000;

  .block-text {
    font-size: 32px;
    font-weight: 500px;
    padding-bottom: 57px;
    box-sizing: border-box;
  }
  .block-button {
    height: 86px;
    border-top: 2px solid #d8d8d8;
    display: flex;
    font-size: 28px;
    line-height: 86px;
    position: relative;
    .button-left {
      width: 50%;
      border-right: 1px solid #d8d8d8;
      color: #666666;
    }
    .button-right {
      width: 50%;
      color: #4083ff;
    }
  }
  img {
    width: 238px;
    height: 211px;
    margin: 64px 112px 61px 140px;
  }
}

.popoverItem{
  font-size: 26px;
  padding: 15px 15px;
  max-width: 400px;
  .popoverItemName{
    margin-bottom: 15px;
  }
 
}
</style>
<style>
.van-popup{
  max-width: 400px;
}

</style>