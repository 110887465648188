import Axios from 'axios'
import router from '../router'

const axios = Axios.create({});

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://onum.universalstar.cn/dingtalkgov/api/v1';
}
axios.defaults.timeout = 30000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.interceptors.response.use(function (res) {
    if ((res.status >= 200 && res.status <= 204) || res.status === 304) {
        if (res.data.code === 0) {
            return res.data.data
        } else {
            return Promise.reject(new Error(res.data.message))
        }
    }
    return Promise.reject(new Error('网络错误'))
}, function (error) {
    if (error && error.response && error.response.status) {
        switch (error.response.status) {
            case 403:
                router.push('/Login')
                throw Error('登录失效请重新登录');
            case 500:
                throw Error('服务器开小差了');
            case 401:
                router.push('/Login')
                throw Error('请登录');
            default:
                throw Error('网络错误');
        }
    }
    return Promise.reject(error)
});


export function get(url, params,config = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: {
                'Us-Token': window.localStorage.getItem('token')
            },
            ...config
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}



export function post(url, params = {}, config = {}) {
    const currentConfig = {
        headers: {
            'us-token': window.sessionStorage.getItem('token')
        },
        ...config
    };
    const d = {
        data: params,
    }
    console.log(d);
    return new Promise((resolve, reject) => {
        axios.post(url, d, currentConfig)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

